import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/public/brand-popeyes-logo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/public/popeyes-est-logo.svg");
;
import(/* webpackMode: "eager", webpackExports: ["ModalErrorProvider"] */ "/workspace/src/app/contexts/generic-error-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/global/footer-expandable-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/src/components/global/header-navigation.tsx");
