// Header & Footer display routes

export const routes = [
  {
    path: '/cardapio',
    label: 'Cardápio',
    elementId: 'plk-menu-btn',
  },
  {
    path: '/restaurantes',
    label: 'Restaurantes',
    elementId: 'plk-restaurants-btn',
  },
  {
    path: '/cupons',
    label: 'Cupons',
    elementId: 'plk-coupons-btn',
  },
  {
    path: '/#delivery',
    label: 'Delivery',
    elementId: 'plk-delivery-btn',
  },
  {
    path: '/fale-conosco',
    label: 'Fale Conosco',
    elementId: 'plk-contact-us-btn',
  },
]
